import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.46:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.46:5000/api/';
}
class InfraInquiryService {
    async createInquiry(data) {
        try {
            const res = await axios.post(API_URL + 'infra/inquiry', data,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res;
        }
        catch (err) {
            console.log(err.response)
            return err.response;
        }
        
    }

    async bulkCreate(data) {
        try {
            const res = await axios.post(API_URL + 'infra/inquiry/upload', data,
            { 
                headers: AuthService.authHeaderForm()
            });
        //console.log(res.data)
            return res.data;
        }
        catch (err) {
            console.log(err.response)
            return err.response;
        }
        
    }

    async getInquiry(id) {
        try {
            const res = await axios.get(API_URL + 'infra/inquiry/' + id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getAllInquiries() {
        try {
            const res = await axios.get(API_URL + 'infra/inquiry/',
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async updateInquiry(id,data) {
        try {
            const res = await axios.put(API_URL + 'infra/inquiry/' + encodeURIComponent(id), data,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async deleteInquiry(id) {
        try {
            const res = await axios.delete(API_URL + 'infra/inquiry/:' + id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

}

export default new InfraInquiryService();